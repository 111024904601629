import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./App.css";


import { ThemeProvider } from "react-bootstrap";

import { useEffect } from "react";

import Home from "./themes/Home";
import { Route, Routes } from "react-router-dom";
import BlogDetails from "./themes/BlogDetails";
import Blogs from "./themes/Blogs";
import VerifiedEmail from "./pages/VerifiedEmail";
// import { Provider } from "react-redux";
// import store from "store";
function App() {


  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="blogs" element={<Blogs />} ></Route>
      <Route path="blogs/:slug" element={<BlogDetails />} />
      <Route path="/verifyEmail/:token" element={<VerifiedEmail />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
