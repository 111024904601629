import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/images/newLogoBlack.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DropdownItem, NavLink } from "react-bootstrap";
export default function Header() {
   const location = useLocation();
  const { hash, pathname, search } = location;
  const [pageID, setPageID] = useState()
const navigate = useNavigate();
const ref = useRef(null);

  const scrolltab = (e) => {
    navigate("/", { state: { targetId: e } });
   
    // setPageID(e)
    // const splitUrl =  pathname.split('/')
    // console.log(splitUrl);
    // if(splitUrl.includes('blogs')){
    //   // scrolltab()
    // }
    // var element = document.getElementById(e);

    // if (pageID === "features") {
    //   var headerOffset = -250;
    // } else {
    //   var headerOffset = 70;
    // }
    
    // var elementPosition = element.getBoundingClientRect().top;
    // var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    // window.scrollTo({
    //   top: offsetPosition,
    //   behavior: "smooth",
    // });
  };

  useEffect(() => {
    
  },[pageID])

  useEffect(() => {
    window.addEventListener("scroll", function (event) {
      const scr = window.pageYOffset;
      const back = this.document.getElementById("home");
      if (scr > 100) {
        back?.classList.add("stickyHeader");
      } else {
        back?.classList.remove("stickyHeader");
      }
    });

  }, []);

  const menutoggle = () => {
    const mobile = document.getElementById('menumobile')
    mobile?.classList.toggle('active')
  }

  return (
    <>

      <Container className="headerbx" fluid>
        <Navbar className="py-0" expand="lg">
          <Container>
            <Link className="logoBox navbar-brand text-decoration-none" to="/">
              <img src={logo} alt="Terpnash" />
           
            </Link>
            <Navbar.Toggle onClick={() => menutoggle()} />
            <div className="menunew" id="menumobile">
              <ul className=" navbarNew my-2 me-5 my-lg-0">
                <li>
                  {" "}
                  <Nav.Link  target="_blank" rel="noopener"  href="https://market.terpnash.com">
                    Marketplace
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="https://market.terpnash.com/art-genres"  target="_blank"  >
                    Top Collections
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="" onClick={() => scrolltab("learningResource")}>
                   Learning Resources
                  </Nav.Link>
                </li>
              </ul>
              <a target="_blank" 
                href={"https://market.terpnash.com/login"}
                rel="noopener noreferrer"
                className="btn btn-primary mobilebtn"
              >
                Sign In
              </a>
            </div>
          </Container>
        </Navbar>
      </Container>
    </>
  );
}
