import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import artist1 from "../assets/images/jpg/NewArtist1.png";
import artist2 from "../assets/images/jpg/NewArtist2.png";
import artist3 from "../assets/images/jpg/NewArtist3.png";
import profile1 from "../assets/images/jpg/ArtistProfile1.png";
import profile2 from "../assets/images/jpg/ArtistProfile2.png";
import profile3 from "../assets/images/jpg/ArtistProfile3.png";
import imgPlaceholder from "../assets/images/imageIcon.svg";

import arrow from "../assets/images/longArrow.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const FeaturedArtist = () => {
  const [featuredArtists, setFeaturedArttists] = useState([]);
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/user/getfeaturedArtists`,
        {}
      )
      .then((response) => {
        if (response?.data?.status === "success") {
          console.log("response", response)
          setFeaturedArttists(response?.data?.data);
        } else {
          return toastr.error("Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    console.log("featuredArtists", featuredArtists);
  }, [featuredArtists]);
  return (
    <>
      <div className="featuredArtistMain">
        <Container>
          <Row>
            <Col lg="12 panelHead viewAll">
              <h3>Featured Artists </h3>
              <p className="mt-4">
                Discover the exceptional talent shaping the future of art.
              </p>
              <Link
                to={"https://market.terpnash.com/artist-view"}
                target="_blank"
              >
                View All{" "}
                <svg
                  width="42"
                  height="16"
                  viewBox="0 0 42 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7C0.447715 7 -5.30158e-08 7.44772 0 8C5.30158e-08 8.55228 0.447715 9 1 9L1 7ZM41.7071 8.7071C42.0976 8.31658 42.0976 7.68341 41.7071 7.29289L35.3431 0.928929C34.9526 0.538405 34.3195 0.538405 33.9289 0.928929C33.5384 1.31945 33.5384 1.95262 33.9289 2.34314L39.5858 8L33.9289 13.6569C33.5384 14.0474 33.5384 14.6805 33.9289 15.0711C34.3195 15.4616 34.9526 15.4616 35.3431 15.0711L41.7071 8.7071ZM1 9L41 9L41 7L1 7L1 9Z"
                    fill="black"
                  />{" "}
                </svg>{" "}
              </Link>
            </Col>
            <Col lg="12 margintopfeature">
            <Row className="justify-content-center">
            <Col lg="3" >
                  <div className='learningCart artistcard'>
                    <img src={artist1} alt='' />
                    <div className="d-flex align-items-center justify-content-between artist">
                      <h4><img src={profile1} height={30} width={30} alt="" /> Ravi Patel</h4>
                      <Link to={'https://market.terpnash.com/view-profile/artist/65e74a7e8b402d9c27e2e91d'} target="_blank">
                        <img src={arrow} width={40} alt="" />
                      </Link>
                    </div>
                  </div>
                </Col>
            <Col lg="3" >
                  <div className='learningCart artistcard'>
                    <img src={artist2} alt='' />
                    <div className="d-flex align-items-center justify-content-between artist">
                      <h4><img src={profile2} height={30} width={30} alt="" /> Seno Poland</h4>
                      <Link to={'https://market.terpnash.com/view-profile/artist/65d33e468b402d9c2767d529'} target="_blank">
                        <img src={arrow} width={40} alt="" />
                      </Link>
                    </div>
                  </div>
                </Col>
                 <Col lg="3" >
                    <div className='learningCart artistcard'>
                        <img src={artist3} alt='' />
                        <div className="d-flex align-items-center justify-content-between artist">
                        <h4><img src={profile3} height={30} width={30} alt="" /> Howard Terpning</h4>
                        <Link  to={'https://market.terpnash.com/view-profile/artist/65144f22ea71e6e8a291be4a'} target="_blank">
                          <img src={arrow}  width={40}  alt="" />
                        </Link>
                        </div>
                    </div>
                </Col>
            </Row>
              <Row className="justify-content-center d-none">
                {featuredArtists.length > 0 &&
                  featuredArtists.map((item, index) => (
                    <Col lg="3" key={index}>
                      <div className="learningCart artistcard">
                        <div className="imageGrid">
                          {/* {item?.map((item2, index) =>
                      ( */}
                          <>
                            <div
                              className={
                                "imgBOx image1 " + (item[0] ? "" : "img1")
                              }
                            >
                              {" "}
                              <img
                                src={
                                  item[0]
                                    ? item[0]?.nft_id?.image_id?.image
                                        ?.water_mark_image_link
                                    : imgPlaceholder
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className={"imgBOx " + (item[1] ? "" : "img2")}
                            >
                              {" "}
                              <img
                                src={
                                  item[1]
                                    ? item[1]?.nft_id?.image_id?.image
                                        ?.water_mark_image_link
                                    : imgPlaceholder
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className={"imgBOx " + (item[2] ? "" : "img3")}
                            >
                              {" "}
                              <img
                                src={
                                  item[2]
                                    ? item[2]?.nft_id?.image_id?.image
                                        ?.water_mark_image_link
                                    : imgPlaceholder
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className={"imgBOx " + (item[3] ? "" : "img4")}
                            >
                              {" "}
                              <img
                                src={
                                  item[3]
                                    ? item[3]?.nft_id?.image_id?.image
                                        ?.water_mark_image_link
                                    : imgPlaceholder
                                }
                                alt=""
                              />
                            </div>
                          </>
                          {/* )

                    )}  */}
                        </div>
                        {/* {item?.map((item2, index) =>
                        <img src={item2?.nft_id?.image_id?.image?.water_mark_image_link} alt='' />
                      )} */}
                        <div className="d-flex align-items-center justify-content-between artist">
                          <h4 key={index}>
                            <img src={profile1} height={30} width={30} alt="" />{" "}
                            {item[0]?.owner_id?.name}
                          </h4>
                          <Link
                            to={
                              "https://market.terpnash.com/view-profile/artist/65e74a7e8b402d9c27e2e91d"
                            }
                            target="_blank"
                          >
                            <img src={arrow} width={40} alt="" />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FeaturedArtist;
