import React, { useState } from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import blogimg from '../assets/images/jpg/blogImg1.jpg'
import calender from "../assets/images/calender.svg";
import eye from "../assets/images/eye.svg";
import { useEffect } from "react";
import axios from "axios";
import toastr from "toastr"
import moment from "moment"
import { Link } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
export default function Blogs() {
  const [loading, setLoading] = useState(false)
  const [filter, setfilter] = useState({
    limit: 10000000,
    orderBy: "date_created",
    order: -1,
    page: 1,
  });
  const [allBlogs, setAllBlogs] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/blog/listTopBlogs`, { filter }).then((response) => {
      if (response?.data?.status === "success") {
        setAllBlogs(response?.data?.data)
      }
      else {
        toastr.error(response?.data?.message)
      }
      setLoading(false)
    });
  }, [filter]);
  return (
    <>
      {loading &&
        <div className='loaderBoxMiane'>
        <Oval
            height={80}
            width={80}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#fff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      }
      <div className="blogsList">
        <div className="blogbann">
          <Container>
            <Row>
              <Col className="text-center" lg={12}>
                <h1>Blogs</h1>
              </Col>
              <Col className="mx-auto text-center" lg={8}>
                <p>
                  Learn how to get the most from your NFTs. Explore the latest
                  NFTs news and tips and tricks all brought to you by your
                  friendly, talkative NFT marketplace on BSC.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="maineContentBlog">
          <Container>
            <Row>
              {allBlogs && allBlogs.map((item, index) =>
                <Col lg="3" >
                                <div className='learningCart'>
                                <img src={item?.image?.link} alt={''} />
                                    <h4>{item?.title}</h4>
                                    <p>{item?.subtitle}</p>
                                    <Link to={`/blogs/${item?.slug}`}>Read more</Link>
                                </div>
                            </Col>
              
                )}


            </Row>
              {/* <Col className="mb-4" sm={6} xs={12} md={4} xl={3}>
                  <Link className="text-decoration-none" to={`/blogs/${item?.slug}`}>
                    <div className="blogcard">
                      <div className="blogimg">
                        <img src={item?.image?.link} alt={''} />
                      </div>
                      <div className="cardContent">
                        <h3>{item?.title}</h3>
                        <p>{item?.subtitle}</p>
                        <div className="d-flex align-items-center justify-content-between datebox">
                          <p className="d-flex align-items-center gap-2 mb-0">
                            <img src={calender} alt={""} /> {moment(item?.date_created).format("DD-MMM-YYYY")}
                          </p>
                          { <p className="d-flex align-items-center gap-2 mb-0 ">
                          <img src={eye} alt={""} /> 100 Views
                        </p> *

                        </div>
                      </div>


                    </div>
                  </Link>
                </Col> */}
          </Container>
        </div>
      </div>
    </>
  );
}
