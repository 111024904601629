import React, { useEffect } from "react";
// import BlogHome from "../pages/blogHome";
// import Feature from "../pages/feature";
// import FeatureArtWork from "../pages/featureArtwork";
// import GuideBox from "../pages/guideBox";
// import Homebanner from "../pages/Homebanner";
// import ListNft from "../pages/listNft";
// import MeetArtist from "../pages/meetArtist";

// import Invite from "../pages/Invite";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Header from "../Pagenew/Header";

import Banner from "../Pagenew/Banner";
import Aboutus from "../Pagenew/About";
import Partner from "../Pagenew/Partner";
import Referral from "../Pagenew/Referral";
import Learningresource from "../Pagenew/Learningresource";
import FeaturedArtist from "../Pagenew/FeaturedArtist";
import Whyterpnash from "../Pagenew/Whyterpnash";
import TrandingArtwork from "../Pagenew/TrandingArtwork";
import HowTerpnashWorks from "../Pagenew/HowTerpnashWorks";

export default function Home() {
  const { state } = useLocation();
  const { targetId } = state || {};
  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
      if (targetId === "features") {
        var headerOffset = -250;
      } else {
        var headerOffset = 70;
      }

      var elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [state,targetId]);
  // const scrolltab = (e) =>{

  //     var element = document.getElementById(e);
  //     var headerOffset = 0;
  //   var elementPosition = element.getBoundingClientRect().top;
  //   console.log(element);
  //     var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  //     window.scrollTo({
  //         top: offsetPosition,
  //         behavior: "smooth"
  //     });
  //   }

  return (
    <>
      <Helmet>
        <title>Terpnash - Making Physical Art Fair and Digital</title>
        <meta
          name="description"
          content={
            "Terpnash is a pioneering NFT marketplace, championing traditional artists and their original, non-digital creations. Explore, buy, and sell amazing artwork from talented creators worldwide."
          }
        />
      </Helmet>
      {/* <div>
        <Header />
      </div> */}
      <div className="bannerBox">
        <Banner />
      </div>
      <div id="aboutus">
        <Aboutus />
      </div>
      <div>{/* <Footer /> */}</div>
          <HowTerpnashWorks />
          <TrandingArtwork />
          <Whyterpnash />
          <FeaturedArtist />
          <div id="learningResource">
          <Learningresource />
          </div>
          <Referral />
          <Partner />


      {/* <Header /> */}
      {/* <div className="bannerBox" >
    
    
     <Banner /> 
    <div >

      <Homebanner />
    </div>
    <div  id='aboutus'>
    <Aboutus />
    </div>
    </div>
  
    <div  id='features'>
    <Feature />
    </div>
  
    <ListNft />
    <div  id='guide'>
    <GuideBox />
    </div>
    <div  id='invite'>
      <Invite />
    </div>
    <FeatureArtWork />
    <div  id='team'>
    <MeetArtist />
    </div>
    <div id="blog">
      <BlogHome />
    </div> 
   
     <Footer /> */}
    </>
  );
}
