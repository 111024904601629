import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/suissefonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import Footer from "./Pagenew/Footer";
import backto from './assets/images/backto.svg'
import Header from './Pagenew/Header';
// import { Provider } from 'react-redux';
// import { store } from "store";
const root = ReactDOM.createRoot(document.getElementById('root'));
const scrolltab = (e) => {
  var element = document.getElementById(e);
  var headerOffset = 0;
  var elementPosition = element.getBoundingClientRect().top;
  console.log(element);
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}
window.addEventListener("scroll", function (event) {
  const scr = window.pageYOffset
  const back = this.document.getElementById('backtos')
  if (scr > 100) {

    back?.classList.add('active')
  } else {
    back?.classList.remove('active')
  }

  })
  var isVerify = false
  const fullpath = window.location.href
  const path =  fullpath.split('/')
  if(path.includes('verifyEmail')){
    isVerify = true
  }
  console.log('dee',isVerify);


root.render(

  <React.StrictMode>
    <BrowserRouter>
      {/* <Provider store={store}> */}
      <div className="backto" id={'backtos'} onClick={() => scrolltab('home')}>
        <img src={backto} alt="backto" />
      </div>
      {isVerify !== true && 
      (<div id='home'>
      <Header />
    </div>)
     }
      <App />


      {isVerify !== true && 
      (  <Footer />)
    }
      
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
