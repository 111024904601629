import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import cardImg from "../assets/images/jpg/learningCartImg.png";
import axios from "axios";
import toastr from "toastr";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import video1 from "../assets/images/jpg/video1.png";
import video2 from "../assets/images/jpg/video2.png";
import video3 from "../assets/images/jpg/video3.jpg";

const Learningresource = () => {
  const [works, setWorks] = useState("Blog");
  const [videoLink, setvideoLink] = useState();

  const [loading, setLoading] = useState(false);
  const [filter, setfilter] = useState({
    limit: 10000000,
    orderBy: "date_created",
    order: -1,
    page: 1,
  });
  const [allBlogs, setAllBlogs] = useState([]);
  const usertype = (e) => {
    setWorks(e);
  };
  const playvideo = (e) => {
    setvideoLink(e);
  };
  const closeVideo = () => {
    setvideoLink();
  };
  useEffect(() => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/blog/listTopBlogs`,
        { filter }
      )
      .then((response) => {
        if (response?.data?.status === "success") {
          setAllBlogs(response?.data?.data);
          console.log("deepakaa", response);
        } else {
          toastr.error(response?.data?.message);
        }
        setLoading(false);
      });
  }, [filter]);
  return (
    <>
      {loading && (
        <div className="loaderBoxMiane">
          <Oval
            height={80}
            width={80}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <div className={"videoBoxMaine " + (videoLink ? "active" : "")}>
        <div className="videoinBox">
          <button onClick={() => closeVideo()} className="closeBtn">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6268 7.12272C18.7435 7.00978 18.8366 6.87466 18.9006 6.72524C18.9647 6.57583 18.9985 6.41511 18.9999 6.25247C19.0014 6.08983 18.9706 5.92851 18.9093 5.77795C18.848 5.62738 18.7574 5.49058 18.6428 5.37551C18.5282 5.26045 18.3919 5.16943 18.2418 5.10777C18.0918 5.04611 17.931 5.01504 17.7689 5.01637C17.6068 5.01771 17.4465 5.05142 17.2975 5.11555C17.1485 5.17967 17.0137 5.27293 16.901 5.38986L12.0069 10.2977L7.11444 5.38986C7.00265 5.26952 6.86784 5.17299 6.71806 5.10604C6.56827 5.03909 6.40658 5.00309 6.24262 5.00019C6.07867 4.99729 5.91581 5.02754 5.76376 5.08915C5.61172 5.15076 5.4736 5.24245 5.35765 5.35877C5.2417 5.47509 5.15029 5.61364 5.08887 5.76616C5.02746 5.91869 4.9973 6.08206 5.00019 6.24653C5.00308 6.411 5.03897 6.5732 5.10571 6.72345C5.17245 6.87371 5.26867 7.00894 5.38864 7.12108L10.2779 12.0306L5.38539 16.9384C5.1697 17.1706 5.05227 17.4777 5.05785 17.7951C5.06343 18.1124 5.19159 18.4152 5.41531 18.6396C5.63903 18.864 5.94086 18.9926 6.2572 18.9982C6.57355 19.0038 6.87971 18.886 7.11118 18.6696L12.0069 13.7618L16.8994 18.6712C17.1309 18.8876 17.437 19.0054 17.7534 18.9998C18.0697 18.9942 18.3715 18.8657 18.5953 18.6412C18.819 18.4168 18.9471 18.114 18.9527 17.7967C18.9583 17.4794 18.8409 17.1722 18.6252 16.94L13.736 12.0306L18.6268 7.12272Z"
                fill="black"
              />
            </svg>
          </button>
          <div className={"inerVideoBox "}>
            <iframe
              class="videoBlogNew"
              src={videoLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>
      <div className="lerningresBoxMaine">
        <Container>
          <Row>
            <Col lg="12 " className="panelHead">
              <h3>Learning Resources</h3>
            </Col>
            <Col lg="12 text-center">
              <div className="tabs">
                <button
                  className={works === "Blog" ? "active" : ""}
                  onClick={() => usertype("Blog")}
                >
                  Blog
                </button>
                <button
                  className={works === "Video" ? "active" : ""}
                  onClick={() => usertype("Video")}
                >
                  Video
                </button>
                <button
                  className={works === "FAQs" ? "active" : ""}
                  onClick={() => usertype("FAQs")}
                >
                  FAQs
                </button>
              </div>
            </Col>
            <Col lg="12 margintoplearning">
              <Row className="justify-content-center">
                {works === "Blog" ? (
                  <>
                    {allBlogs &&
                      allBlogs.map((item, index) => (
                        <Col lg="3">
                          <div className="learningCart">
                            <img src={item?.image?.link} alt={""} />
                            <h4>{item?.title}</h4>
                            <p>{item?.subtitle}</p>
                            <Link to={`/blogs/${item?.slug}`}>Read more</Link>
                          </div>
                        </Col>
                      ))}
                  </>
                ) : works === "Video" ? (
                  <>
                    <Col lg="3">
                      <div
                        onClick={() =>
                          playvideo(
                            "https://www.youtube.com/embed/8R7a-dMcgw4?si=svHnFxip8dyVGuem"
                          )
                        }
                        className="learningCart videoBox"
                      >
                        <img src={video1} alt="" />
                        <div className="videooverlay">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM10.783 7.99L16.427 11.126C16.5828 11.2126 16.7126 11.3393 16.803 11.493C16.8934 11.6467 16.9411 11.8217 16.9411 12C16.9411 12.1783 16.8934 12.3533 16.803 12.507C16.7126 12.6607 16.5828 12.7874 16.427 12.874L10.783 16.01C10.6002 16.1116 10.3941 16.1636 10.1851 16.161C9.97599 16.1584 9.77123 16.1013 9.59107 15.9952C9.4109 15.8891 9.26159 15.7377 9.15791 15.5562C9.05424 15.3746 8.9998 15.1691 9 14.96V9.04C8.9998 8.83092 9.05424 8.62541 9.15791 8.44384C9.26159 8.26227 9.4109 8.11094 9.59107 8.00484C9.77123 7.89874 9.97599 7.84156 10.1851 7.83895C10.3941 7.83635 10.6002 7.88842 10.783 7.99Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div
                        onClick={() =>
                          playvideo(
                            "https://www.youtube.com/embed/lWGagLNV_P4?si=J8UbG17t720Zovpr"
                          )
                        }
                        className="learningCart videoBox"
                      >
                        <img src={video2} alt="" />
                        <div className="videooverlay">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM10.783 7.99L16.427 11.126C16.5828 11.2126 16.7126 11.3393 16.803 11.493C16.8934 11.6467 16.9411 11.8217 16.9411 12C16.9411 12.1783 16.8934 12.3533 16.803 12.507C16.7126 12.6607 16.5828 12.7874 16.427 12.874L10.783 16.01C10.6002 16.1116 10.3941 16.1636 10.1851 16.161C9.97599 16.1584 9.77123 16.1013 9.59107 15.9952C9.4109 15.8891 9.26159 15.7377 9.15791 15.5562C9.05424 15.3746 8.9998 15.1691 9 14.96V9.04C8.9998 8.83092 9.05424 8.62541 9.15791 8.44384C9.26159 8.26227 9.4109 8.11094 9.59107 8.00484C9.77123 7.89874 9.97599 7.84156 10.1851 7.83895C10.3941 7.83635 10.6002 7.88842 10.783 7.99Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div
                        onClick={() =>
                          playvideo(
                            "https://www.youtube.com/embed/xYXi89WJoTw?si=ayG28W5YKmSzCV8j"
                          )
                        }
                        className="learningCart videoBox"
                      >
                        <img src={video3} alt="" />
                        <div className="videooverlay">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM10.783 7.99L16.427 11.126C16.5828 11.2126 16.7126 11.3393 16.803 11.493C16.8934 11.6467 16.9411 11.8217 16.9411 12C16.9411 12.1783 16.8934 12.3533 16.803 12.507C16.7126 12.6607 16.5828 12.7874 16.427 12.874L10.783 16.01C10.6002 16.1116 10.3941 16.1636 10.1851 16.161C9.97599 16.1584 9.77123 16.1013 9.59107 15.9952C9.4109 15.8891 9.26159 15.7377 9.15791 15.5562C9.05424 15.3746 8.9998 15.1691 9 14.96V9.04C8.9998 8.83092 9.05424 8.62541 9.15791 8.44384C9.26159 8.26227 9.4109 8.11094 9.59107 8.00484C9.77123 7.89874 9.97599 7.84156 10.1851 7.83895C10.3941 7.83635 10.6002 7.88842 10.783 7.99Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col lg={9}>
                    <Accordion className="newAccordian">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>What is an NFT?</Accordion.Header>
                        <Accordion.Body>
                        <p>NFTs are non-fungible tokens. They are digital assets that are unique and not interchangeable. NFTs are stored on a blockchain and can represent anything from digital art to in-game items. An NFT can’t be easily traded or exchanged unless done through proper channels.</p>
                        <p>One of the most notable aspects of NFTs is how they are represented as unique and non-interchangeable objects, similar to physical artwork. The term "non-fungible" means that an NFT cannot be substituted with another like a common item can be used multiple times.</p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>What is a blockchain?</Accordion.Header>
                        <Accordion.Body>
                        A blockchain is a distributed ledger that records transactions across nodes in the network. Transactions are grouped into blocks, which are time-stamped and logged on the ledger.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Why should I be interested in turning my art into an NFT?</Accordion.Header>
                        <Accordion.Body>
                        <p>Turning your art into an NFT can be beneficial in several ways. The first benefit of minting your NFT is that the information becomes immutable (cannot be changed) and available to the public at all times. As a result, it gets easier to prove you are the creator, irrespective of the buyer or where it ends up.</p>
                        <p>Additionally, there is no middleman in an NFT sales process. You will receive the amount the buyer pays(minus fees) in your MetaMask wallet.</p>
                        <p>Finally, you'll be entitled to secondary sales benefits. Thanks to blockchain technology, you can specify a fee (5-15%) that would be charged for subsequent sales of your NFT. As your NFTs become more popular, you can earn more royalties without having to do anything.</p>
                        <p>Our platform allows you to reach a truly global market.</p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header> Are there any risks/downsides to converting my art to an NFT?</Accordion.Header>
                        <Accordion.Body>
                        <p>Yes, there are risks involved in converting your art to an NFT. Many NFT marketplaces allow users to list NFTs without paying any fees and with little scrutiny. In addition, there have been environmental concerns for NFT transactions on some blockchain networks due to the amount of electricity and CO2 generated.</p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header> What are the benefits of NFTs?</Accordion.Header>
                        <Accordion.Body>
                        <p>The benefits of NFTs include the ability to trade or sell individual items without affecting the value of the underlying asset, and the ability to create a market for digital goods and services.</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                )}

                {/* <Col lg="3" >
                                <div className='learningCart'>
                                    <img src={cardImg} alt='' />
                                    <h4>What is NFT</h4>
                                    <p>Have you heard whispers of million-dollar digital art or collectible tweets?</p>
                                    <a href='#'>Read more</a>
                                </div>
                            </Col>
                            <Col lg="3" >
                                <div className='learningCart'>
                                    <img src={cardImg} alt='' />
                                    <h4>What is NFT</h4>
                                    <p>Have you heard whispers of million-dollar digital art or collectible tweets?</p>
                                    <a href='#'>Read more</a>
                                </div>
                            </Col> */}
              </Row>
            </Col>
            <Col lg="12 text-center">
              <Link
                className="text-decoration-none"
                to={
                  works === "Blog"
                    ? "/blogs"
                    :  works === "Video" ? "https://market.terpnash.com/blogs":'https://market.terpnash.com/user-faq'
                }
                target={works === "Video" || works === "FAQs" ? "_blank" : ""}
              >
                <button className="LearnMoreBtn">
                  Learn More
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.3322 7.08337L18.3238 7.08337L18.3154 7.08394L18.1798 7.09307L18.1714 7.09364L18.163 7.09478C17.5526 7.17757 17.0822 7.70031 17.0822 8.33337V8.34184L17.0828 8.35028L17.092 8.48598L17.0925 8.49433L17.0937 8.50263C17.1764 9.11306 17.6992 9.58337 18.3322 9.58337H25.3142L16.3347 18.5642L12.5509 14.7805L12.5444 14.774L12.5374 14.768L12.4253 14.6712L12.4183 14.6652L12.4109 14.6597C11.9214 14.2965 11.2271 14.3366 10.7831 14.7804L10.7831 14.7805L2.78311 22.7805L2.77659 22.787L2.77057 22.794L2.67374 22.9062L2.66776 22.9132L2.66229 22.9205C2.29906 23.4101 2.33918 24.1043 2.7831 24.5483L2.78311 24.5483C3.27126 25.0365 4.06273 25.0365 4.55088 24.5483L11.667 17.4321L15.4509 21.2159L15.4574 21.2224L15.4643 21.2283L15.5764 21.3251L15.5834 21.3312L15.5908 21.3367C16.0804 21.7 16.7747 21.6597 17.2187 21.2159L17.2187 21.2159L27.0837 11.349L27.0842 18.3386V18.347L27.0848 18.3555L27.094 18.4912L27.0945 18.4996L27.0957 18.5079C27.1785 19.1182 27.7013 19.5886 28.3342 19.5886C29.0247 19.5886 29.5842 19.0289 29.5842 18.3386L29.5837 8.29536V8.28293L29.5824 8.27055L29.5699 8.14495L29.5685 8.13126L29.5657 8.1178L29.5475 8.03247L29.5454 8.02265L29.5426 8.01303L29.5171 7.92759L29.513 7.91378L29.5073 7.90054L29.4557 7.78012L29.449 7.76439L29.4402 7.74972L29.3679 7.62956L29.3604 7.61716L29.3516 7.6057L29.2936 7.53053L29.2868 7.52173L29.2793 7.51356L29.225 7.45484L29.2251 7.45471L29.218 7.44762C29.1098 7.3395 28.9853 7.25401 28.8505 7.19469L28.8505 7.19467L28.8473 7.19332L28.7669 7.15925L28.7521 7.15299L28.7367 7.14867L28.6049 7.11185L28.5887 7.10732L28.572 7.105L28.4383 7.08644L28.4212 7.08407L28.4039 7.08407L18.3322 7.08337Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.5"
                    />
                  </svg>
                </button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Learningresource;
