import React from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// Modules
import { Container, Row, Col } from "react-bootstrap";

import imgss from "../assets/images/jpg/howTerpnashWorks.jpg";
import bannerImg from "../assets/images/bannerImgNew.svg";

import ImageGallery from "react-image-gallery";

export default function Homebanner() {
  var settings = {
    dots: false,
    prevArrow: "",
    nextArrow: "",
    arrows: false,
    autoplay: true,
    fade: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const images = [
    {
      original: "images/jpg/newnft1.jpg",
      //thumbnail: "images/jpg/image1.jpg",
    },
    {
      original: "images/jpg/newnft2.jpg",
      // thumbnail: "images/jpg/image2.jpg",
    },
    {
      original: "images/jpg/newnft3.jpg",
      //thumbnail: "images/jpg/image3.jpg",
    },
    {
      original: "images/jpg/newnft4.jpg",
      // thumbnail: "images/jpg/image4.jpg",
    },
    {
      original: "images/jpg/newnft5.jpg",
      // thumbnail: "images/jpg/image5.jpg",
    },
  ];

  return (
    <section className=" position-relative pt-5">
      {/* <div className="banner-overlay"></div> */}
      <Container>
        <Row className="pt-5">
          <Col lg={5} md={12} sm={12} className="my-auto">
            <div className="banner-content bannertext">
              <h3>Terpnash</h3>
              {/* <p>The leading Artistic NFT Marketplace On Polygon Admire And Purchase The Most Stunning NFT Artworks In The Industry.</p> */}
              {/* <h1>WHY DIGITAL ART</h1> */}
              <p className="mt-2">
                World's First Marketplace for HD  Digital Edition of Original
                Physical Art
              </p>
              <div className="btnm">
                <a
                  href="https://market.terpnash.com/"
                  rel="noreferrer"
                  target={"_blank"}
                  className="Musebtn1 "
                >
                  Explore
                </a>
                <a
                  href="https://market.terpnash.com/users/register"
                  rel="noreferrer"
                  target={"_blank"}
                  className="Musebtn2 "
                >
                  Create
                </a>
              </div>
            </div>
          </Col>

          <Col lg={7} md={12} sm={12}>
            {/* <div className="sliderBox">
              <ImageGallery autoPlay={true} items={images} />
            </div> */}
            <div className="sliderBoxNew">
              <Slider {...settings}>
                {images.map((items) => (
                  <img src={items.original} alt="" />
                ))}
              </Slider>
            </div>
            {/* <div className="bnannerNewimg">
              <img src={bannerImg} className="img-fluid" alt="" />
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
