import React, { useEffect, useState } from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import calender from "../assets/images/calender.svg";
import blogimg from '../assets/images/jpg/blog.jpg'
import blog1 from "../assets/images/jpg/blogImg1.jpg";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import toastr from "toastr"
import moment from "moment"
import { Helmet } from "react-helmet";
import { Oval } from 'react-loader-spinner'
export default function BlogDetails() {
  const location = useLocation()
  const [slug, setSlug] = useState('')
  const [blog, setBlog] = useState({})
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [loading, setLoading] = useState(false)

  console.log("location", location)
  const [filter, setFilter] = useState({
    limit: 4,
    orderBy: "date_created",
    order: -1,
    page: 1,
    notInclude: ''
  });


  useEffect(() => {
    const slug = location?.pathname.split("/")[2]
    setFilter((p) => ({ ...p, notInclude: slug }))
    setSlug(slug)
  }, [location])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (slug) {
      let body = {}
      body.slug = slug
      console.log("body", body)
      setLoading(true)
      axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/blog/getBlogBySlug`, body).then((response) => {
        console.log("response", response);
        if (response?.data?.status === "success") {
          setBlog(response?.data?.data)
        }
        else {
          toastr.error(response?.data?.message)
        }
        setLoading(false)
      });
    }
  }, [slug]);

  useEffect(() => {
    console.log("cajscakjsbcjkabjkc", blog)
  }, [blog])

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/blog/listTopBlogs`, { filter }).then((response) => {
      console.log("rltdresponse", response);
      if (response?.data?.status === "success") {
        setRelatedBlogs(response?.data?.data)
      }
      else {
        toastr.error(response?.data?.message)
      }
    });
  }, [filter]);
  const scrolltab = (e) => {


    var element = document.getElementById(e);
    var headerOffset = 0;
    var elementPosition = element.getBoundingClientRect().top;
    console.log(element);
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  return (
    <>
      {loading &&
        <div className='loaderBoxMiane'>
          <Oval
            height={80}
            width={80}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#fff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      }
      <Helmet>
        <title>{blog?.title}</title>
        <meta name="description" content={blog?.subtitle} />
      </Helmet>
      <div className="blogsList" id="blogtop">
        <div className="blogbann">
          <Container>
            <Row>
              <Col lg={12}>
                <h1>{blog?.title}</h1>
                <p>{blog?.subtitle}</p>
                <p className="d-flex align-items-center gap-2 mb-0 dateBox">
                  <img src={calender} alt={""} /> {moment(blog?.date_created).format("DD-MMM-YYYY")}
                  {/* <img src={calender} alt={""} /> {blog?.date_created} */}

                </p>

              </Col>
            </Row>
          </Container>
        </div>
        <div className="maineContentBlog">
          <Container>
            <Row>
              <Col lg={12}>
                {/* {blog?.description} */}
                <img src={blog?.image?.link} alt={''} className={'img-fluid w-100'} />
                <div className="blogData" dangerouslySetInnerHTML={{ __html: blog?.description }}></div>
              </Col>
            </Row>
          </Container>
        </div>
        {relatedBlogs.length > 0 ?
          <div className="relatedBlogs">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="realetdhead">
                    <h4>Related Blogs</h4>
                    <h5><Link className="text-decoration-none" to="/blogs">View All</Link></h5>
                  </div>
                </Col>
              </Row>
              <div className="MaineBlogHome">
                <Row  >
                  {relatedBlogs && relatedBlogs.map((item, index) => (
                    <Col className="mb-3" sm={6} xs={12} md={4} xl={3} key={index} onClick={() => setFilter((p) => ({ ...p, notInclude: item?.slug }))}>
                      <Link to={`/blogs/${item?.slug}`} onClick={() => scrolltab("blogtop")}>
                        {/* onClick={()=>{setF}} */}
                        <div className="blogBox smallBox">
                          <div className="imgbox">
                            <img src={item?.image?.link} alt={''} />
                          </div>
                          <div className="blogHomCont">
                            <h4>
                              {item?.title}
                            </h4>
                            <p>
                              {item?.subtitle}
                            </p>
                            <p className="d-flex align-items-center gap-2 mb-0">
                              <img src={calender} alt={""} /> {moment(item?.date_created).format("DD-MMM-YYYY")}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
          </div>
          : ""}
      </div>
    </>
  );
}
