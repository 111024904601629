import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useParams } from 'react-router-dom';
import toastr from "toastr";
import emailverifyimg from '../assets/images/emailVerifyImg.svg';
import linkexpireimg from '../assets/images/linkexpire.svg';
import { FormGroup } from "react-bootstrap";
import axios from "axios"
export default function VerifiedEmail() {

    // const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
    const { token } = useParams();
    const [verify, setVerify] = useState("verifying")
    const [alreadyVerified, setAlreadyVerified] = useState(false);
    const [linkExpired, setLinkExpired] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState()
    const [isValid, setIsValid] = useState(true);
    const [checkEmail, setCheckEmail] = useState(false)
    useEffect(() => {
        let body = {}
        body.token = token
        axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/newsLetter/verifyMail`, body).then((response) => {
            if (response?.data?.message === "Token not verified") {
                setVerify("failure")
                setAlreadyVerified(false)
                setEmailSent(false)
                setLinkExpired(false)
                return
            }
            if (response?.data?.message === "Email Already Verified") {
                setVerify("failure")
                setAlreadyVerified(true)
                // setEmailSent(true)
                return
                // setLinkExpired(true)
            }
            if (response?.data?.status === "success") {

                setVerify('success')
                // return toastr.success("We have sent you verification mail")
            }
            else {
                return toastr.error(response?.data?.message)
            }
        });
    }, [token])

    const handleSubscribe = () => {
        if (email === '') {
            return toastr.error("Email is required");
        }
        if (!isValid) {
            return toastr.error("Please enter a valid Email");
        }
        let body = {}
        body.email = email
        body.marketPlace = false
        axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}/newsLetter/addMail`, body).then((response) => {
            if (response?.data?.status === "success") {
                setEmail('')
                setCheckEmail(true)
                return toastr.success("We have sent you verification mail")
            }
            else {
                return toastr.error(response?.data?.message)
            }
        });
    }

    const handleChange = (email) => {
        setEmail(email)
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setIsValid(isValidEmail);

    }
    return (
        <>
            <div className="EmailVerifyBox verifiedMail container">
                <div className="emailbefore">

                    {verify === "failure" && alreadyVerified === false && emailSent === false ? (
                        <img src={linkexpireimg} className="mx-auto mt-3 mb-4" width={400} alt="Email Verification" />
                    ) : (
                        <img src={emailverifyimg} className="mx-auto mt-3 mb-4" width={400} alt="Email Verification" />
                    )}

                    {alreadyVerified === true && verify === "failure" ? (<><h2>Email already Verified</h2>  <Link className="btn btn-primary px-5 outline-0 border-0 mt-3" to={'/login'}>Back to login</Link></>) : (" ")}
                    {verify === "success" ? (<> <p>Your Email Has Been Verified</p><h2>Successfully</h2>    <Link className="btn btn-primary px-5 outline-0 border-0 mt-3 btnrm" to={'https://market.terpnash.com/'}>Back to login</Link></>) : (" ")}
                    {verify === "verifying" ? (<p className="pmg">Verifying email...</p>) : (" ")}
                    <div>
                        {verify === "failure" && alreadyVerified === false && emailSent === false ? (
                            <>{checkEmail === false ? (<>{linkExpired === false ? (<h2>Failed</h2>) : (<h2>Verification link has been expired</h2>)}
                                <FormGroup className="form-group position-relative">
                                    <input type="text" id="uploadartNew" placeholder="Enter Your email" name="email" value={email} onChange={(e) => { handleChange(e.target.value) }} />
                                </FormGroup>
                                <button className="btn btn-primary px-5 outline-0 border-0 mt-3" onClick={(e) => handleSubscribe(e.target.value)} >Resend Email</button></>) : <p>Check Your Email</p>}
                            </>
                        ) : (<>
                            {verify !== "success" && linkExpired !== false ? (
                                <>
                                    <FormGroup className="form-group position-relative">
                                        <input type="text" id="uploadartNew" placeholder="Enter Your email" name="email" /></FormGroup>
                                    <button className="btn btn-primary px-5 outline-0 border-0 mt-3" onClick={(e) => handleSubscribe(e.target.value)}>Resend Email</button></>)
                                : ("")}
                        </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}